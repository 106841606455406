import React from "react";
import PropTypes from "prop-types";

import { Card, Accordion, Button } from "react-bootstrap";
import PageSection from "components/PageSection";

import "./FAQ.scss";

const FAQ = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, FAQs } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <h1 dangerouslySetInnerHTML={{
        __html: header
      }} />
      <p dangerouslySetInnerHTML={{
        __html: subheader
      }} />
      <Accordion>
        {FAQs.map((faq) => (
          <Card key={faq.id}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="light" eventKey={faq.id} className>{faq.header}</Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={faq.id}>
              <Card.Body>{faq.content}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </PageSection>
  );
};

FAQ.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

FAQ.defaultProps = {
  className: null,
  frontmatter: null,
};

export default FAQ;
