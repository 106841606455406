import React from "react";
import PropTypes from "prop-types";

import { Container, Card, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import Image from "components/Image";
import "./Top.scss";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { styledHeader, subheader, imageFileName, buyText, buyAnchor, installText, installAnchor } = frontmatter;

  return (
    <Card className="banner bg-primary text-dark text-center">
      <Card.ImgOverlay className="no-padding">
        <Container>
          <div className="banner-content text-left">
            <Row>
              <Col>
                <h1 className="banner-header">
                  <span className='text-dark'>{styledHeader.first}</span>
                  <span className='text-light'>{styledHeader.second}</span>
                  <span className='text-dark'>{styledHeader.third}</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="banner-subheader" md={6} xs={8}>{subheader}</Col>
            </Row>
            <Row>
              <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                <Row className="banner-extra">
                  <Col md={6}>
                    <Link
                      className="btn btn-dark btn-lg btn-block text-light margin-top cursor-pointer"
                      activeClass="active"
                      to={buyAnchor}
                      smooth="easeInOutQuart"
                    >{buyText}</Link>
                  </Col>
                  <Col md={6}>
                    <Link
                      className="btn btn-light btn-lg btn-block text-dark margin-top cursor-pointer"
                      activeClass="active"
                      to={installAnchor}
                      smooth="easeInOutQuart"
                    >{installText}</Link>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
                <Image className="banner-image" fileName={imageFileName} alt={subheader} />
              </Col>
            </Row>
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
