import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Card } from "react-bootstrap";
import PageSection from "components/PageSection";
import Image from "components/Image";

import "./Innovation.scss";

const Cards = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header, sections } = frontmatter;

    return (
        <PageSection className={clsx("bg-secondary text-light", className)} id={anchor}>
            <h1 dangerouslySetInnerHTML={{
                __html: header
            }} />
            <br />
            <br />
            {sections.map((section) => (
                <div key={section.header}>
                    <h4>{section.header}</h4>
                    <Row>
                        {section.box.map((box) => (
                            <Col lg={4} md={4} key={box.header} className='margin-top'>
                                <Card body className='bg-light text-dark h-100'>
                                    <div className='text-center'>
                                        <Image className='box-image' fileName={box.image} alt={box.header} />
                                    </div>
                                    <Card.Body className='fixed-height'>
                                        <Card.Title className="text-uppercase">{box.header}</Card.Title>
                                        <Card.Text>
                                            <p>{box.content}</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <br />
                </div>
            ))
            }
        </PageSection >
    );
};

Cards.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Cards.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Cards;
