import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import "./Item.scss";
import CircleIcon from "./CircleIcon";

const Item = ({ header, content, iconName, className, ...restProps }) => {

  return (
    <Row className={className} {...restProps}>
      <Col xs={2} sm={2} md={2} lg={2}>
        {/* <img src={icon} alt="Icon" /> */}
        <CircleIcon iconName={iconName} />
      </Col>
      <Col xs={10} sm={10} md={10} lg={10}>
        <h6>{header}</h6>
        <p>{content}</p>
      </Col>
    </Row>
  );
};

Item.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
};

Item.defaultProps = {
  header: "",
  content: "",
  iconName: "",
  className: null,
};

export default Item;
