import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-scroll";
import PageSection from "components/PageSection";
import Image from "components/Image";

import "./Explanation.scss";

const Explanation = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header, subheader, imageFileName, card } = frontmatter;

    return (
        <PageSection className={clsx("bg-light text-dark", className)} id={anchor}>
            <h1 dangerouslySetInnerHTML={{
                __html: header
            }} />
            <p dangerouslySetInnerHTML={{
                __html: subheader
            }} />

            <Row>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
                    <Image className="image" fileName={imageFileName} alt={header || subheader} />
                </Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 4, order: 2 }}>
                    <Card body className='bg-primary text-dark'>
                        <Card.Header as="h5" className="bg-info text-white">{card.header}</Card.Header>
                        <Card.Body>
                            <Card.Text>{card.content}</Card.Text>
                            <Link
                                className="btn btn-dark btn-lg btn-block text-light margin-top cursor-pointer"
                                activeClass="active"
                                to={card.buyAnchor}
                                smooth="easeInOutQuart"
                            >{card.buyText}</Link>
                            <Link
                                className="btn btn-light btn-lg btn-block text-dark margin-top cursor-pointer"
                                activeClass="active"
                                to={card.installAnchor}
                                smooth="easeInOutQuart"
                            >{card.installText}</Link>
                        </Card.Body>
                    </Card>
                    <br />
                </Col>
            </Row>
        </PageSection>
    );
};

Explanation.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Explanation.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Explanation;
