import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Card } from "react-bootstrap";
import PageSection from "components/PageSection";

import "./Introduction.scss";
import Item from "components/Item";

const Introduction = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, styledHeader, subheader, features } = frontmatter;

    return (
        <PageSection className={clsx("bg-dark text-white", className)} id={anchor}>
            <h1>
                <span className='text-primary'>{styledHeader.first}</span>
                <span className='text-light'>{styledHeader.second}</span>
                <span className='text-light text-decoration-line-through'>{styledHeader.third}</span>
                <span className='text-light'>{styledHeader.fourth}</span>
            </h1>
            <p dangerouslySetInnerHTML={{
                __html: subheader
            }} />
            <Card body className='bg-white text-dark'>
                <Row>
                    {features.map((feature) => (
                        <Col sm={12} md={6} key={feature.header}>
                            <Item
                                header={feature.header}
                                content={feature.content}
                                iconName={feature.iconName} />
                        </Col>
                    ))}
                </Row>
            </Card>
        </PageSection>
    );
};

Introduction.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Introduction.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Introduction;
