import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import Icon from "components/Icon";

import "./Wallet.scss";

const Wallet = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, styledHeader, subheader, appstore, playstore } = frontmatter;

    return (
        <PageSection className={clsx("bg-primary text-dark", className)} id={anchor}>
            <Row>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }}>
                    <h1>
                        <span className='text-dark'>{styledHeader.first}</span>
                        <span className='text-light'>{styledHeader.second}</span>
                        <span className='text-dark'>{styledHeader.third}</span>
                    </h1>
                    <br />
                    <h4 dangerouslySetInnerHTML={{
                        __html: subheader
                    }} />
                    <br />
                    <Row>
                        <Col lg={6}>
                            <a className="text-light btn btn-dark btn-block btn-lg margin-top">
                                <Icon iconName="AppStoreIcon" />
                                &nbsp;&nbsp;{appstore}
                            </a>
                        </Col>
                        <Col lg={6}>
                            <a className="text-dark btn btn-light btn-block btn-lg margin-top">
                                <Icon iconName="GooglePlayIcon" />
                                &nbsp;&nbsp;{playstore}
                            </a>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
                    <iframe className="video-frame" title="demo-video" width="420" height="250" src="https://www.youtube.com/embed/4m5DSQQ5Nns" />
                    <br />
                </Col>
            </Row>
        </PageSection>
    );
};

Wallet.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Wallet.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Wallet;
