import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-scroll";
import { NavDropdown } from "react-bootstrap";
import IconText from 'components/IconText';

import "./NavItemSelector.scss";

const NavItemSelector = ({ title, anchors, pdf, onClick }) => {
  return (
    <NavDropdown
      title={title}
      id="language-dropdown"
      className="language-selector"
    >
      {Object.keys(anchors).map((anchor) => {
        const ar = anchors[anchor].replace('.pdf', '');
        return (
          (
            anchors[anchor].includes('.pdf') ?
              <a rel="noopener noreferrer" target="_blank" href={`../../${pdf[ar]}`} className="dropdown-item">
                <IconText iconName="FilePdfIcon" text={pdf[`${ar}T`]} />
              </a> :
              <Link
                key={anchor}
                to={anchors[anchor]}
                className="dropdown-item"
                smooth="easeInOutQuart"
                onClick={onClick}
              >{anchors[anchor]}</Link>
          )
        );
      })}
    </NavDropdown>
  );
};

NavItemSelector.propTypes = {
  title: PropTypes.string,
  anchors: PropTypes.object,
  pdf: PropTypes.object,
  onClick: PropTypes.func,
};

NavItemSelector.defaultProps = {
  title: "",
  anchors: "",
  pdf: {},
  onClick: null,
};

export default NavItemSelector;
