import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Top from "views/Top";
import Footer from "views/Footer";
import * as Sections from "views/Sections";
import SEO from "components/SEO";
import LanguageSelector from "components/LanguageSelector";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import fileNameToSectionName from "utils/fileNameToSectionName";

import "../style/main.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          brand
          anchor
          copyright
          header
          subheader
          styledHeader {
            first
            second
            third
            fourth
          }
          email
          imageFileName
          logo {
            first
            second
            third
          }
          form {
            name
            email
            message
            submit
          }
          menuText
          privacyHref
          privacyText
          social {
            facebook
            github
            linkedin
            medium
            twitter
          }
          termsHref
          termsText
          title
          features {
            iconName
            header
            content
          }
          cards
          card {
            header
            subheader
            content
            buyText
            buyAnchor
            installText
            installAnchor
          }
          appstore
          playstore
          FAQs {
            id
            header
            content
          }
          buyText
          buyAnchor
          installText
          installAnchor
          sections {
            header
            box {
              image
              header
              content
            }
          }
          pdf {
            fintom
            fintomT
            upow
            upowT
          }
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Index = ({ data, pathContext: { langKey, defaultLang, langTextMap } }) => {
  const {
    site: { siteMetadata: { keywords, description }, },
    allMarkdownRemark: { nodes },
  } = data;

  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(nodes);

  let langSelectorPart;
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap} />
    );
  }

  return (
    <>
      <SEO lang={langKey} title="Index" keywords={keywords} description={description} />
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
        extraItems={langSelectorPart}
      />
      <Top frontmatter={topNode.frontmatter} />
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }) => {
          const sectionComponentName = fileNameToSectionName(fileName);
          const SectionComponent = Sections[sectionComponentName];

          return SectionComponent ? (
            <SectionComponent
              key={sectionComponentName}
              className={null}
              frontmatter={frontmatter}
            />
          ) : null;
        })
      }
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

Index.defaultProps = {
  pathContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Index;
