import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Button } from "react-bootstrap";
import PageSection from "components/PageSection";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, email, form } = frontmatter;

  return (
    <PageSection className={clsx("bg-secondary", className)} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center text-white">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-white mb-5">
            {subheader}&nbsp;
            <a href={`mailto:${email}`}>{email}</a>
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 8, offset: 2 }} className="text-center">
          <form
            action='https://formspree.io/f/mqkvlpgp'
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <Row>
              <Col lg={6} className="text-left text-white">
                <label htmlFor="name" className="w-100">
                  {form.name}<input id="name" className="form-control" type="text" name="name" required />
                </label>
              </Col>
              <Col lg={6} className="text-left text-white">
                <label htmlFor="email" className="w-100">
                  {form.email}<input id="email" className="form-control" type="email" name="email" required />
                </label>
              </Col>
            </Row>
            <Row>
              <Col className="text-left text-white">
                <div>
                  <label htmlFor="message" className="w-100">
                    {form.message}<textarea id="message" className="form-control" name="message" required />
                  </label>
                </div>
              </Col>
            </Row>
            <Button type="submit" className="margin-top" variant="primary">{form.submit}</Button>
          </form>
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
