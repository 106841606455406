import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col, Carousel } from "react-bootstrap";
import { Link } from "react-scroll";
import PageSection from "components/PageSection";
import Image from "components/Image";

import "./Cards.scss";

const Cards = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header, subheader, cards, buyText, buyAnchor } = frontmatter;

    return (
        <PageSection className={clsx("bg-dark text-light", className)} id={anchor}>
            <Row>
                <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
                    <Carousel>
                        {cards.map((card) => (
                            <Carousel.Item key={card}>
                                <Image className="image" fileName={card} alt={header || subheader} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
                <Col xs={{ span: 12, order: 2 }} md={{ span: 5, offset: 1, order: 2 }}>
                    <h1 dangerouslySetInnerHTML={{
                        __html: header
                    }} />
                    <br />
                    <h4 dangerouslySetInnerHTML={{
                        __html: subheader
                    }} />
                    <br />
                    <Row>
                        <Col>

                            <Link
                                className="btn btn-light btn-lg btn-block text-dark margin-top cursor-pointer"
                                activeClass="active"
                                to={buyAnchor}
                                smooth="easeInOutQuart"
                            >{buyText}</Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PageSection>
    );
};

Cards.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object
};

Cards.defaultProps = {
    className: null,
    frontmatter: null
};

export default Cards;
